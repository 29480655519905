/**
 * レッスン画面遷移
 * @param companyId
 * @param roomId
 * @returns
 */
export const routeJoinLesson = (companyId: string, roomId: string) => {
  const path = `/join-lesson/preview/${companyId}/${roomId}/`;
  return {
    path,
    //   FIXME 他と合わせてwindowでの遷移、react-routerの機能を使うように変更する場合は、Hooksに変更
    open: () => {
      window.open(path, '_blank');
    },
    replace: () => {
      window.location.replace(path);
    },
  };
};
